const ARROWS     = `arrows:`;
const PAGINATION = `pagination:`;
const AUTOPLAY   = `autoplay:`;

export const EVENT_MOUNTED            = 'mounted';
export const EVENT_READY              = 'ready';
export const EVENT_MOVE               = 'move';
export const EVENT_MOVED              = 'moved';
export const EVENT_CLICK              = 'click';
export const EVENT_ACTIVE             = 'active';
export const EVENT_INACTIVE           = 'inactive';
export const EVENT_VISIBLE            = 'visible';
export const EVENT_HIDDEN             = 'hidden';
export const EVENT_REFRESH            = 'refresh';
export const EVENT_UPDATED            = 'updated';
export const EVENT_RESIZE             = 'resize';
export const EVENT_RESIZED            = 'resized';
export const EVENT_DRAG               = 'drag';
export const EVENT_DRAGGING           = 'dragging';
export const EVENT_DRAGGED            = 'dragged';
export const EVENT_SCROLL             = 'scroll';
export const EVENT_SCROLLED           = 'scrolled';
export const EVENT_OVERFLOW           = 'overflow';
export const EVENT_DESTROY            = 'destroy';
export const EVENT_ARROWS_MOUNTED     = `${ ARROWS }mounted`;
export const EVENT_ARROWS_UPDATED     = `${ ARROWS }updated`;
export const EVENT_PAGINATION_MOUNTED = `${ PAGINATION }mounted`;
export const EVENT_PAGINATION_UPDATED = `${ PAGINATION }updated`;
export const EVENT_NAVIGATION_MOUNTED = 'navigation:mounted';
export const EVENT_AUTOPLAY_PLAY      = `${ AUTOPLAY }play`;
export const EVENT_AUTOPLAY_PLAYING   = `${ AUTOPLAY }playing`;
export const EVENT_AUTOPLAY_PAUSE     = `${ AUTOPLAY }pause`;
export const EVENT_LAZYLOAD_LOADED    = 'lazyload:loaded';

/** @internal */
export const EVENT_SLIDE_KEYDOWN     = 'sk';
export const EVENT_SHIFTED           = 'sh';
export const EVENT_END_INDEX_CHANGED = 'ei';
